@mixin gradient ($startColor: #eee, $endColor: white) {
	background-color: $startColor;
	background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background: -webkit-linear-gradient(top, $startColor, $endColor);
	background: -moz-linear-gradient(top, $startColor, $endColor);
	background: -ms-linear-gradient(top, $startColor, $endColor);
	background: -o-linear-gradient(top, $startColor, $endColor) {
    /* W3C */
    background: linear-gradient(to bottom, $startColor 0%, $endColor 100%);
    /* IE6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${startColor}', endColorstr='${endColor}',GradientType=0 ); } }

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em; }

@function rem($pixels) {
  @return #{$pixels/16}rem; }

$accentBase: #8C2B0D;
$headerColor: #394a59;
$contentBackground: #d5e6e6;
$contentHeaderColor: #ac3b19;

@import 'reset';

html, body {
    height: 100%; }

html {

    font-size: rem(14);

    @media (min-width: em(1300)) {
        font-size: rem(15); }

    @media (min-width: em(1900)) {
        font-size: rem(16); }

    @media (min-width: em(2100)) {
        font-size: rem(17); }

    @media (min-width: em(2400)) {
        font-size: rem(18); } }

body {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAJklEQVQIW2N0cHD4z8DAwPDkyRMGRhAHxJCRkWFgVFFR+Q9igAAA/w8K7VgMlFcAAAAASUVORK5CYII=) repeat;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.wrapper {
    background-color: $contentBackground;
    height: 100%;
    width: 60em;
    @media (max-width: 60em) {
        width: 100%; }
    @media (max-width: em(280,14)) {
        width: em(280,14); }
    margin: 0 auto;

    .content-wrapper {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        margin: 0 auto -1.5625em;
        box-shadow: 0.125em 0 0.625em #000000,-0.125em 0 0.625em #000000;

        header {
            @media (max-width: em(600)) {
                font-size: rem(13); }

            height: em(100);
            color: $headerColor;
            display: grid;
            @media (min-width: em(600)) {
                grid-template-columns: em(112) em(392) auto;
                grid-template-rows: em(70) auto; }
            @media (max-width: em(600)) {
                grid-template-columns: em(380) auto;
                grid-template-rows: em(70) auto; }

            a:hover, a:visited, a {
                color: $headerColor; }

            .logo {
                grid-row: 1 / span 2;
                margin-left: 0.8em;
                @media (max-width: em(600)) {
                    display: none; }
                img {
                    width: em(100); } }

            h1 {
                font-family: 'karpach-header';
                font-size: 4.75em;
                line-height: 1em;
                height: 1em;
                display: inline-block;
                vertical-align: top;
                letter-spacing: -0.12em;
                margin-left: 0.1em;
                a {
                    text-decoration: none; }
                @media (max-width: em(600)) {
                    grid-row: 1 / span 2;
                    grid-column: 1; } }

            .social-media-container {
                font-family: 'karpach-header';
                text-align: right;
                margin-right: 0.7em;
                margin-left: 2em;
                margin-top: 0.5em;
                overflow: hidden;
                height: 1.6em;
                a {
                    display: inline-block;
                    height: 1.2em;
                    width: 1.4em;
                    text-decoration: none;
                    font-size: 1.5em;
                    i {
                        display: inline-block; }
                    .visual-studio:before {
                        content: "\e902";
                        color: #5c2d91; }
                    .linkedin:before {
                        content: "\e904";
                        color: #0077b5; }
                    .twitter:before {
                        content: "\e906";
                        color: #1da1f2; }
                    .github:before {
                        content: "\e905"; } } }

            h2 {
                font-weight: bold;
                font-size: 1.5em;
                @media (max-width: em(600)) {
                    grid-column: 1; }
                @media (min-width: em(600)) {
                    grid-column: 2; }
                grid-row: 2;
                padding-left: em(7);
                a {
                    text-decoration: none; } } }
        nav {
            @media (max-width: em(600)) {
                font-size: rem(14); }
            height: 2.5em;
            border-top: solid 1px darken($accentBase,6%);
            border-bottom: solid 1px darken($accentBase,6%);
            @include gradient(lighten($accentBase,2%), darken($accentBase,2%));
            box-shadow: 0 0.125em 0.225em #888;
            margin-bottom: 1em;
            padding-left: 0.9em;
            display: flex;

            a {
                &:nth-child(3) {
                    @media (max-width: em(600)) {
                        display: none; } }
                font-size: rem(14);
                font-weight: 500;
                font-size: 1.0625em;
                color: white;
                text-decoration: none;
                line-height: 2.35em;
                display: inline-block;
                padding: 0 1em;
                border-right: solid 1px darken($accentBase,4%);
                box-shadow: 1px 0 0 lighten($accentBase,4%);

                &.active {
                    position: relative;
                    @include gradient(lighten($accentBase,6%), lighten($accentBase,2%));

                    &:after {
                        content: ' ';
                        width: 0;
                        height: 0;
                        border-left: em(3) solid transparent;
                        border-right: em(3) solid transparent;
                        border-bottom: em(3) solid #888;
                        position: absolute;
                        right: 50%;
                        bottom: -em(1)px; } } }

            .search {
                margin-left: auto;
                margin-right: 0.7em;
                height: 2.5em;
                line-height: 2.5em;
                position: relative;
                @media (max-width: em(359)) {
                    display: none; }

                svg {
                    position: absolute;
                    top: 0.8em;
                    left: 0.4em;
                    width: 1em;
                    height: 1em; }

                input {
                    @include gradient(darken($contentBackground,5%), lighten($contentBackground,5%));
                    border-radius: 0.4em;
                    border: 1px solid darken($contentBackground,30%);
                    height: 1.5em;
                    padding-left: 1.9em;
                    width: 15em;
                    @media (max-width: em(600)) {
                        width: 8em;
                        padding-left: 1.5em; }

                    &:focus {
                        outline-width: 0;
                        border: 1px solid darken($contentBackground,70%); }
                    &.searching {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0; } }
                ul {
                    position: absolute;
                    top: 2.03em;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: $contentBackground;
                    border: 1px solid darken($contentBackground,30%);
                    border-bottom-left-radius: 0.4em;
                    border-bottom-right-radius: 0.4em;
                    li {
                        line-height: 0.8em;
                        a {
                            font-size: 0.8rem;
                            border: none;
                            box-shadow: none;
                            color: #555;
                            padding-left: 0.5em;
                            padding-right: 0;
                            text-overflow: ellipsis;
                            height: 2em;
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            &::before {
                                content: "\1F4C6";
                                padding-right: 0.3em; } }
                        &.active {
                            background-color: darken($contentBackground,20%); } } } } }

        .container {
            padding: 0 1.25em 2em 1.25em;
            background-color: $contentBackground;

            h1, h1 a, h1 a:hover, h1 a:visited, h1 a:link {
                font-weight: normal;
                font-size: 1.3rem;
                color: $contentHeaderColor;
                text-decoration: none;
                width: 100%;
                margin-bottom: 0.4em;
                margin-top: 0.5em; }

            h1 {
                border-bottom: solid 2px $contentHeaderColor; }

            h2, h2 a, h2 a:hover, h2 a:visited {
                font-weight: normal;
                font-size: 1.2rem;
                color: $contentHeaderColor;
                margin-bottom: em(15);
                margin-top: em(15);
                text-decoration: none; }

            h3 {
                font-weight: normal;
                font-size: 1.1rem;
                color: $contentHeaderColor;
                border-bottom: none;
                width: 100%;
                margin-bottom: em(8); }

            ul li a, ul li a:hover, ul li a:visited {
                color: $contentHeaderColor;
                width: 100%;
                text-decoration: none;
                margin-bottom: 4px; }

            article {
                ol li {
                    width: 100%;
                    margin-bottom: em(4); }
                ol {
                    list-style-type: decimal;
                    margin-bottom: 0.8em;
                    margin-left: em(20);
                    clear: both; }
                p.red {
                    color: red; }

                table {
                    border-top: solid 1px #b0b0b0;
                    border-left: solid 1px #b0b0b0; }

                table tr td, table tr th {
                    border-right: solid 1px #b0b0b0;
                    border-bottom: solid 1px #b0b0b0;
                    padding: em(5); }

                p {
                    word-wrap: break-word; }

                strong {
                    font-weight: 600;
                    color: #069; }

                code {
                    color: $contentHeaderColor; } }
            ul li {
                list-style-type: none; }

            p {
                color: #000000;
                margin-bottom: 0.8em;
                line-height: 1.4; }

            p a {
                color: #000000; }

            #contact-form {
                max-width: em(400);
                padding-top: em(20);
                label {
                    display: block;
                    margin-bottom: em(8);
                    font-size: 1rem;
                    color: #555; }
                input[type="text"],
                input[type="email"],
                textarea {
                    width: 100%;
                    padding: em(8) em(10);
                    margin-bottom: em(20);
                    border: 1px solid #ccc;
                    border-radius: em(5);
                    font-size: 1rem;
                    color: #333; }
                textarea {
                    height: em(250); }
                button {
                    padding: em(8) em(15);
                    @include gradient(lighten($accentBase,2%), darken($accentBase,2%));
                    box-shadow: 0 0.125em 0.225em #888;
                    border: none;
                    border-radius: em(5);
                    font-size: 1rem;
                    color: #fff;
                    cursor: pointer;
                    &:hover {
                        background: none;
                        background-color: lighten($accentBase,2%); } } }

            .signature {
                color: $contentHeaderColor;
                text-align: left;
                margin-top: em(10);
                font-weight: normal;
                padding-top: em(7);
                border-top: dotted 1px $contentHeaderColor; }

            .comment {
                display: grid;
                grid-template-columns: em(70) minmax(0,1fr);
                border-bottom: dotted 1px $contentHeaderColor;
                padding-bottom: em(10);
                margin-bottom: em(10);
                font-size: 0.95rem;
                a {
                    color: #000; }
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0; }
                img {
                    grid-row: 1 / span 20;
                    width: em(60); }
                h3 {
                    font-size: 1rem;
                    padding-top: em(2);
                    margin-bottom: em(5); }
                p {
                    margin-bottom: 0;
                    word-wrap: break-word;
                    a {
                        color: #000; } }

                .response {
                    display: grid;
                    grid-template-columns: em(70) minmax(0,1fr);
                    border-left: solid 5px $contentHeaderColor;
                    border-top: solid 1px $contentHeaderColor;
                    border-right: solid 1px $contentHeaderColor;
                    border-bottom: solid 1px $contentHeaderColor;
                    padding: em(8);
                    margin-top: em(8);
                    img {
                        grid-row: 1 / span 10; }
                    a {
                        color: #000; } } }

            .signature a,
            .signature a:hover {
                color: $contentHeaderColor;
                font-weight: normal;
                text-decoration: none; }

            ul.projects li {
                font-weight: normal;
                background-image: url('/images/icon_projects.jpg');
                background-position: left;
                background-repeat: no-repeat;
                margin-bottom: 1.2em; }

            ul.projects li h2 {
                margin-left: em(50); }

            ul.projects li p {
                margin-left: em(50, 12.8); }

            ul.projects li h2 a {
                color: $contentHeaderColor; }

            ul.projects li p {
                text-indent: 0px!important;
                margin-bottom: em(5, 12.8)!important; }

            ul.projects li span a {
                margin-left: em(50, 12.8); }

            img {
                max-width: 100%; }

            form {
                width: 25em; }

            .form-group {
                margin-bottom: 1em; }

            label {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 0.3em;
                font-weight: 700; }

            .highlight {
                margin-bottom: 0.8em;
                pre {
                    padding: 1em;
                    overflow-x: auto;
                    line-height: 1.4; } }

            #breadcrumbs {
                display: flex;
                flex-wrap: wrap;
                li {
                    ::after {
                        content: '»';
                        margin-right: 0.3em;
                        vertical-align: text-top; }
                    a {
                        color: $accentBase;
                        font-weight: normal;
                        font-size: 0.9rem;
                        text-decoration: none;
                        font-weight: 500;
                        white-space: nowrap;
                        @media (max-width: em(600)) {
                            white-space: normal; } } }
                .active {
                    ::after {
                        visibility: hidden; } } } } }


    footer {
        border-top: 2px solid $accentBase;
        color: $accentBase;
        font-size: 0.625em;
        height: 1.5625em;
        margin: 0 0.625em;
        padding-top: 0.625em;
        text-align: center;
        display: block;
        a {
          color: $accentBase;
          text-decoration: none;
          &::after {
            content: " | "; } } }

    .sitemap {
        li {
            padding-left: 1em;
            line-height: 1.3; } } }

#description p {
    line-height: 40px;
    margin-bottom: 10px;
    text-indent: 8px;
    text-align: justify; }

@font-face {
	font-family: 'karpach-header';
	src:url('/fonts/karpach-header.eot') format('embedded-opentype'), url('/fonts/karpach-header.ttf') format('truetype'), url('/fonts/karpach-header.woff') format('woff'), url('/fonts/karpach-header.svg?c685iy#karpach-header') format('svg') {}
	font-weight: normal;
	font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
	font-family: 'karpach-header';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.icon-menu-close:before {
    content: "\e900"; }

.icon-menu-open:before {
    content: "\e901"; }

.icon-logo:before {
	content: "\e600"; }
